import { getCurrentMetrika } from '@/utils/metrika';

/**
 * Отправляет событие в Yandex.Metrika
 * @param {string} action - Название действия
 */
export default function sendMetrik(action) {
  if (typeof window !== 'undefined' && typeof window.ym !== 'undefined') {
    try {
      const YM_ID = getCurrentMetrika();
      window.ym(YM_ID, 'reachGoal', action);
      console.log('send Metrik:', action);
    } catch (error) {
      console.error(error.message);
    }
  } else {
    console.warn('Yandex.Metrika API не доступен.');
  }
}
