<template>
  <v-sheet
    elevation="0"
    :class="noBottomPadding ? 'mb-0' : 'mb-8'"
    class="pa-0 w-100 d-flex flex-column"
    rounded
  >
    <v-label
      v-if="label"
      class="text-black opacity-100 semibold mb-0"
    >
      {{
        label
      }}
    </v-label>
    <v-text-field
      v-bind="attrs"
      ref="inputField"
      class="pa-0"
      variant="underlined"
      :class="centeredText ? 'text-center' : ''"
      hide-details="auto"
      @input="updateValue($event.target.value)"
    />
    <v-card-subtitle
      v-if="errorMessages.length > 0"
      class="text-customRed px-0"
    >
      {{
        errorMessages
      }}
    </v-card-subtitle>
  </v-sheet>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount, useAttrs } from 'vue';
import IMask from 'imask';

const props = defineProps({
  errorMessages: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  customMask: {
    type: String,
    required: false,
  },
  noBottomPadding: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    required: false,
    default: "underlined"
  },
  centeredText: {
    type: Boolean,
    required: false,
  }
});

const emit = defineEmits(["update:modelValue"]);
const attrs = useAttrs();
const innerValue = ref(props.modelValue);
const inputField = ref(null);
const inputMask = ref(null);

watch(innerValue, (newValue) => {
  emit("update:modelValue", newValue); // Передаем значение с маской
});

// Function to handle input events and update the parent component
const updateValue = (value) => {
  innerValue.value = value;
  emit("update:modelValue", value);
};

const initializeMask = () => {
  if (props.customMask && inputField.value) {
    const realInput = inputField.value.$el.querySelector('input');

    if (realInput) {
      inputMask.value = IMask(realInput, {
        mask: props.customMask,
        lazy: false, // Показывать маску даже если поле пустое
        definitions: {
          '0': /[0-9]/,
        },
      });

      inputMask.value.on('accept', () => {
        innerValue.value = inputMask.value.value;
      });
    }
  }
};

onMounted(() => {
  initializeMask();
});

onBeforeUnmount(() => {
  if (inputMask.value) {
    inputMask.value.destroy();
  }
});

const updateMask = (newMask) => {
  if (inputMask.value) {
    inputMask.value.updateOptions({
      mask: newMask,
    });
  } else {
    initializeMask();
  }
};

watch(
    () => props.customMask,
    (newMask) => {
      updateMask(newMask);
    }
);
</script>

<style scoped>
.input {
  all: unset;
  border: none;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  width: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #2b2b2b;
}
</style>
