<script setup>
import EditProfileForm from "@/components/Profile/EditProfile/EditProfileForm.vue";
import {useAuthStore} from "@/store/AuthStore";
import {storeToRefs} from "pinia";
import {formatPhoneNumber} from "@/helpers";
import ProfileCustomButton from "@/components/Profile/components/ProfileCustomButton.vue";
import {computed} from "vue";
import {GENDERS, getGenders} from "@/constants/api";
import {useI18n} from "vue-i18n";

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const { locale } = useI18n();

// MALE | FEMALE | ANOTHER
const userGender = computed(() => {
  return user.value.gender ? user.value.gender : 'ANOTHER';
})

const handleChangeGender = async (newGender) => {
  try {
    await authStore.editProfile({gender: newGender === 'ANOTHER' ? null : newGender});
    await authStore.getUserInfo();
  } catch (e) {
    console.error(e);
  }
}

</script>

<template>
  <v-sheet class="pa-0 ma-0 mb-4 default-profile-title">
    {{ $t('profile') }}
  </v-sheet>
  <v-sheet class="pb-10">
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="3"
      >
        <v-sheet class="w-100 d-flex flex-column align-center align-md-start">
          <v-sheet class="mb-5">
            <v-img
              width="180"
              height="180"
              class="rounded-lg"
              :src="user.avatar_url"
            />
          </v-sheet>
          <v-sheet class="semibold text-h6 mb-3">
            {{ user.name }} {{ user.last_name }}
          </v-sheet>
          <v-sheet class="semibold text-subtitle-2 text-grey-darken-4 d-flex flex-row align-center ga-2 mb-2">
            <v-sheet>
              <v-icon
                color="grey-darken-3"
                icon="mdi-phone-in-talk"
                size="20"
              />
            </v-sheet>
            <v-sheet style="line-height: 1">
              {{ formatPhoneNumber(user.phone_number) }}
            </v-sheet>
          </v-sheet>
          <v-sheet class="semibold text-subtitle-2 text-grey-darken-4 d-flex flex-row align-center ga-2 mb-5">
            <v-sheet>
              <v-icon
                color="grey-darken-3"
                icon="mdi-at"
                size="20"
              />
            </v-sheet>
            <v-sheet style="line-height: 1">
              {{ user.email ? user.email : '-' }}
            </v-sheet>
          </v-sheet>
        </v-sheet>
        <v-sheet class="d-flex flex-column align-center ga-2">
          <profile-custom-button
            v-for="gender in getGenders(locale)"
            :key="gender.value"
            :on-click="() => handleChangeGender(gender.value)"
            :is-active="userGender === gender.value"
          >
            {{ gender.title }}
          </profile-custom-button>
        </v-sheet>
      </v-col>
      <v-col
        cols="12"
        md="8"
        lg="9"
      >
        <v-sheet class="text-grey-darken-1 mb-6">
          {{ $t('editProfileText') }}
        </v-sheet>
        <edit-profile-form />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<style scoped>
.icon {
  svg {
    path {
      fill: red;
    }
  }
}
</style>
