<script setup>
import { useI18n } from 'vue-i18n';
import {computed} from "vue";
import {useMenuStore} from "@/store/MenuStore";
import Cookies from "js-cookie";
import {COOKIE_LANGUAGE_NAME} from "@/constants/cookies";
import {useCartStore} from "@/store/CartStore";

const { locale, availableLocales } = useI18n();
const menuStore = useMenuStore();
const cartStore = useCartStore();

const changeLanguage = (lang) => {
  locale.value = lang;
  Cookies.set(COOKIE_LANGUAGE_NAME, lang)
};

const listItems = computed(() => {
  return availableLocales.map((locale) => {
    return {
      title: locale === 'en' ? 'English' : 'Русский',
      value: locale,
      image: locale === 'en'
          ? new URL('@/assets/en.svg', import.meta.url).href
          : new URL('@/assets/ru.svg', import.meta.url).href,
    }
  })
})

const currentLangImage = computed(() =>
    locale.value === 'en'
        ? new URL('@/assets/en.svg', import.meta.url).href
        : new URL('@/assets/ru.svg', import.meta.url).href
);

const handleChangeLanguage = async (lang) => {
  changeLanguage(lang);
  await menuStore.getMenuSections(menuStore.currentCity.id, lang)
  await menuStore.getCities();

  const newCity = menuStore.cities.find((city) => city.id === menuStore.currentCity.id);

  await menuStore.setCurrentCity(newCity);

  await cartStore.getCart(lang);
}

</script>

<template>
  <v-sheet class="pa-0 ma-0">
    <v-menu>
      <template #activator="{ props }">
        <v-img
          v-bind="props"
          width="25"
          height="25"
          :src="currentLangImage"
          class="cursor-pointer"
        />
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in listItems"
          :key="index"
          :value="index"
          @click="handleChangeLanguage(item.value)"
        >
          <template #prepend>
            <v-img
              width="25"
              height="25"
              :src="item.image"
            />
          </template>
          <v-list-item-title class="ml-2">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-sheet>
</template>

<style scoped>

</style>
