<script setup>
  import OrderTableItem from "@/components/Profile/OrdersHistory/OrderTableItem.vue";
  import {format} from "date-fns";
  import {computed, ref} from "vue";
  import {useCartStore} from "@/store/CartStore";
  import {getCurrencySymbol} from "@/helpers";
  import {useMenuStore} from "@/store/MenuStore";

  const props = defineProps({
    order: {
      type: Object,
      required: true,
    }
  })

  const cartStore = useCartStore();
  const menuStore = useMenuStore();
  const isLoadingNewOrder = ref(false);
  const formattedDate = computed(() => {
    return format(new Date(props.order.completion_time), "dd.MM.yyyy")
  })

  const handleRepeatOrder = async () => {
    try {
      isLoadingNewOrder.value = true;
      await cartStore.addSeveralItemsToCart(props.order.items);
    } finally {
      isLoadingNewOrder.value = false;
    }
  }

</script>

<template>
  <v-sheet class="w-100 pa-0 ma-0 d-flex flex-row h-100">
    <v-sheet
      class="d-flex flex-row justify-space-between flex-nowrap w-100 pa-0 ma-0 rounded-shadow cursor-pointer"
    >
      <order-table-item :has-divider="true">
        {{ formattedDate }}
      </order-table-item>
      <order-table-item :has-divider="true">
        {{ order.order_number }}
      </order-table-item>
      <order-table-item :has-divider="true">
        {{ order.address_delivery ? order.address_delivery : $t('pickup') }}
      </order-table-item>
      <order-table-item
        :has-divider="true"
        class="currency-symbol"
      >
        {{ order.total_price }}&nbsp;{{ getCurrencySymbol(menuStore.currentCity) }}
      </order-table-item>
      <order-table-item :has-divider="true">
        {{ order.status_title }}
      </order-table-item>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.rounded-shadow {
  border-radius: 8px;
  box-shadow: 0 0 7px 0 rgba(194, 194, 194, 0.5);
}

.floating-button {
  position: absolute;
  top: calc(50% - 30px);
  right: -101px;
  padding: 0 16px;
  height: 80%;
  background: #E31517;
  color: #ffffff;
  transition: all 0.3s;
  border: 1px solid transparent;
  &:hover {
    background: white;
    color: #282828;
    border: 1px solid #E31517;
  }
}
</style>
