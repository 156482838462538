<template>
  <base-layout :is-loading="isLoading">
    <template #header>
      <HeaderComponent />
    </template>
    <MainPage />
  </base-layout>
</template>

<script setup>
import MainPage from "@/components/Home/MainComponent.vue";
import BaseLayout from "@/components/Layouts/BaseLayout.vue";
import HeaderComponent from "@/components/Header/HeaderComponent.vue";
import {computed, onBeforeMount} from "vue";
import {useMenuStore} from "@/store/MenuStore";
import {useAuthStore} from "@/store/AuthStore";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import router from "@/router";
import {storeToRefs} from "pinia";

const menuStore = useMenuStore();
const authStore = useAuthStore();
const {isAuthenticated} = storeToRefs(authStore);
const isLoading = computed(
    () => menuStore.isLoadingSections || menuStore.isLoadingCities
);

onBeforeMount(async () => {
  await authStore.checkAuth();
  const sochiCity = menuStore.cities.find((city) => city.title === 'Сочи');

  if (isAuthenticated.value) {
    const userCityRouteName = CyrillicToTranslit().transform(authStore.user.city.title).toLowerCase();
    await router.push({
      path: `/${userCityRouteName}`
    })
  } else if (sochiCity) {
    const userCityRouteName = CyrillicToTranslit().transform(sochiCity.title).toLowerCase();

    await router.push({
      path: `/${userCityRouteName}`
    })
  }
})
</script>
