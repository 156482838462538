<script setup>
import {computed, ref, watch} from "vue";
import EditProfileField from "@/components/Profile/EditProfile/EditProfileField.vue";
import {useAuthStore} from "@/store/AuthStore";
import {storeToRefs} from "pinia";
import ProfileCustomButton from "@/components/Profile/components/ProfileCustomButton.vue";
import {useForm} from "vee-validate";
import * as yup from "yup";
import EditProfileDateField from "@/components/Profile/EditProfile/EditProfileDateField.vue";
import {format, isValid, parse} from "date-fns";
import {useAlertStore} from "@/store/AlertStore";
import {useI18n} from "vue-i18n";

  const DATE_FORMAT = "dd.MM.yyyy";
  const authStore = useAuthStore();
  const alertStore = useAlertStore();
  const { user } = storeToRefs(authStore);
  const schema = yup.object({
    name: yup.string().min(4,'Имя не может быть короче 4 символов'),
    lastName: yup.string(),
    phone: yup.string().matches(/^7\d{10}$/, 'Введите номер в формате 7XXXXXXXXXX').required('Телефон обязателен'),
    birthday: yup
        .string()
        .required("Дата обязательна для заполнения.")
        .test(
            "is-valid-date",
            `Введите корректную дату в формате ${DATE_FORMAT}.`,
            (value) => {
              if (!value) return false;
              const parsedDate = parse(value, DATE_FORMAT, new Date());
              return isValid(parsedDate);
            }
        ),
    email: yup.string().email('Введите корректный email').required('Email обязателен')
        .test('contains-at-and-dot', 'Email должен содержать "@" и "."', (value) => {
          return value ? value.includes('@') && value.includes('.') : false;
        })
  });
  const isFormSending = ref(false);
  const { getLocaleMessage, locale } = useI18n();


// Инициализация формы
  const {values, errors, validate, setValues} = useForm({
    validationSchema: schema,
    initialValues: {
      name: user.value.name,
      lastName: user.value.last_name,
      phone: user.value.phone_number,
      birthday: user.value.birthday ? format(user.value.birthday, DATE_FORMAT) : null,
      email: user.value.email,
    },
  });

  // Переменная для отслеживания, были ли изменения
  const isModified = ref(false);

  // Валидация при изменении значений
  watch(values, async () => {
    isModified.value = true; // Отмечаем, что были изменения
    await validate(); // Проводим валидацию при каждом изменении
  });

  // Проверка, когда форма валидна и были ли изменения
  const isFormValid = computed(() => {
    return isModified.value && Object.keys(errors.value).length === 0;
  });

  // Обработка формы
  const onSubmit = async () => {
    try {
      isFormSending.value = true;
      const birthday = parse(values.birthday, DATE_FORMAT, new Date());
      const newBirthDay = format(birthday, "yyyy-MM-dd");

      const data = {
        name: values.name,
        last_name: values.lastName,
        birthday: newBirthDay,
        email: values.email,
      }
      // Сюда добавить обработку отправки
      await authStore.editProfile(data);
      await authStore.getUserInfo();
      alertStore.openAlert('success', 'Данные сохранены')
    } finally {
      isFormSending.value = false;
    }
  };

</script>

<template>
  <v-sheet class="d-flex flex-column flex-lg-row flex-wrap ga-3 edit-profile-grid mb-4">
    <edit-profile-field
      v-model="values.name"
      :error-message="errors.name"
      :title="getLocaleMessage(locale).name"
      name="name"
    />
    <edit-profile-field
      v-model="values.lastName"
      :error-message="errors.lastName"
      :title="getLocaleMessage(locale).lastName"
      name="lastName"
    />
    <edit-profile-field
      v-model="values.phone"
      :error-message="errors.phone"
      :title="getLocaleMessage(locale).phone"
      name="phone"
    />

    <edit-profile-date-field
      v-model="values.birthday"
      :error-message="errors.birthday"
      :title="getLocaleMessage(locale).birthday"
      name="birthday"
      :on-value-change="(date) => {
        const newDate = format(new Date(date), DATE_FORMAT);
        setValues({birthday: newDate});
      }"
    />

    <edit-profile-field
      v-model="values.email"
      :error-message="errors.email"
      title="Email"
      name="email"
    />
  </v-sheet>
  <v-sheet
    class="w-100 d-flex flex-row justify-end"
  >
    <v-sheet
      style="max-width: 180px;"
      class=" w-100"
    >
      <profile-custom-button
        :on-click="onSubmit"
        :disabled="!isFormValid"
        :loading="isFormSending"
      >
        {{ $t('save') }}
      </profile-custom-button>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.edit-profile-grid > * {
  width: 100%;
  box-sizing: border-box;

  @media(min-width: 1280px) {
    width: calc(50% - 10px) !important; /* делим на 2 колонки с небольшим отступом */

  }
}

.edit-profile-grid > *:last-child {
  width: 100% !important;
}

@media (max-width: 600px) {
  .edit-profile-grid > * {
    width: 100%;
  }
}
</style>
