<template>
  <base-sheet
    rounded="5"
  >
    <base-title class="mb-3 semibold">
      {{ $t('addDevices') }}
    </base-title>
    <v-container class="pa-0 pt-4 w-100 d-flex flex-column align-center ga-5">
      <device-item
        v-for="device in deviceSection.products"
        :key="device.id"
        :device="device"
      />
    </v-container>
  </base-sheet>
</template>

<script setup>
import BaseTitle from "@/components/base/BaseTitle/BaseTitle.vue";
import {computed} from "vue";
import DeviceItem from "@/components/Cart/CartDevices/DeviceItem/DeviceItem.vue";
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import BaseSheet from "@/components/base/BaseSheet/BaseSheet.vue";

const menuStore = useMenuStore();
const {sections} = storeToRefs(menuStore)

const deviceSection = computed(() => sections.value.find((section) => section.title === 'Столовые приборы'))

</script>
