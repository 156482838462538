import { createI18n } from 'vue-i18n';
import en from '@/locales/en.json';
import ru from '@/locales/ru.json';

const messages = {
    en,
    ru,
};

const i18n = createI18n({
    legacy: false, // Используем Composition API
    locale: 'ru', // Язык по умолчанию
    fallbackLocale: 'en', // Резервный язык
    messages,
});

export default i18n;
