<script setup>
import {computed, onMounted, ref} from "vue";
import MenuButton from "@/components/base/MenuButton.vue";
import { useMenuStore } from "@/store/MenuStore";
import { useAuthStore } from "@/store/AuthStore";
import { storeToRefs } from "pinia";
import {useDisplay} from "vuetify";
import {useCartStore} from "@/store/CartStore";
import {getCurrencySymbol} from "@/helpers";
import {useAlertStore} from "@/store/AlertStore";
import {useI18n} from "vue-i18n";
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const menuStore = useMenuStore();
const authStore = useAuthStore();
const cartStore = useCartStore();
const alertStore = useAlertStore();
const {currentCity} = storeToRefs(menuStore)
const { isAuthenticated } = storeToRefs(authStore);
const selectedModifiers = ref([]);
const isItemCreation = ref(false);
const { mobile } = useDisplay();
const { smAndDown } = useDisplay();
const { getLocaleMessage, locale } = useI18n();

const isTashkentCity = computed(() => currentCity.value.title === 'Ташкент');
const isSheregeshCity = computed(() => currentCity.value.title === 'Шерегеш');

const updateSelectedModifier = (modifier, selectedItem) => {
  const existingModifier = selectedModifiers.value.find(
    (mod) => mod.modifier.id === modifier.id
  );

  if (existingModifier) {
    // Если модификатор уже выбран, обновляем его
    existingModifier.selectedId = selectedItem;
  } else {
    // Иначе добавляем новый модификатор с выбранным элементом
    selectedModifiers.value.push({ modifier, selectedId: selectedItem });
  }
};

const updatedModifiers = computed(() => {
  return props.product.modifiers.map((modifier) => {
    const existingSelection = selectedModifiers.value.find(
      (mod) => mod.modifier.id === modifier.id
    );
    return {
      ...modifier,
      selected: existingSelection ? existingSelection.selectedId : null,
    };
  });
});

const cartProduct = computed(() => cartStore.itemsToShow.find((cartItem) => {
  return cartItem.product.id === props.product.id;
}))

const handleAddProductToCart = async () => {
  const addProduct = async () => {
    if (props.product.ingredients.length !== 0) {
      menuStore.openIngredientsModal(props.product);
      return;
    }
    const modifiers = selectedModifiers.value.map(
        (modifier) => modifier.selectedId
    );

    if (updatedModifiers.value.length !== modifiers.length) {
      await alertStore.openToast('error', getLocaleMessage(locale.value).selectModifier);
      return;
    }

    isItemCreation.value = true;
    const newProduct = { ...props.product, quantity: 1 };
    await menuStore.addProductToCart(newProduct, modifiers);
    isItemCreation.value = false;
  }

  if (!isAuthenticated.value) {
    authStore.setPostAuthAction(addProduct)
    authStore.openLoginModal();
    return;
  }

  await addProduct();
};

const handleRemoveItemFromCart = () => {
  menuStore.removeProductFromCart(cartProduct.value);
};

onMounted(() => {
  if (cartProduct.value) {
    cartProduct.value.modifier_items.map((modifier) => {
      const existingSelection = cartProduct.value.product.modifiers.find(
          (mod) => {
            return mod.items.some((item) => item.id === modifier.id);
          }
      );

      if (existingSelection) {
        selectedModifiers.value.push({ modifier: existingSelection, selectedId: modifier.id });
      }
    })
  }

})

const riceText =  computed(() => {
  if (isTashkentCity.value) {
    return `${getLocaleMessage(locale.value).Kcal}:\u00A0367,90, ${getLocaleMessage(locale.value).proteins}:\u00A07,80\u00A0${getLocaleMessage(locale.value).gr}., ${getLocaleMessage(locale.value).fats}:\u00A01,10\u00A0${getLocaleMessage(locale.value).gr}., ${getLocaleMessage(locale.value).carbohydrates}:\u00A081,70\u00A0${getLocaleMessage(locale.value).gr}.`;
  }

  if (isSheregeshCity.value) {
    return `${getLocaleMessage(locale.value).Kcal}:\u00A0221, ${getLocaleMessage(locale.value).proteins}:\u00A05\u00A0${getLocaleMessage(locale.value).gr}., ${getLocaleMessage(locale.value).fats}:\u00A01\u00A0${getLocaleMessage(locale.value).gr}., ${getLocaleMessage(locale.value).carbohydrates}:\u00A049\u00A0${getLocaleMessage(locale.value).gr}.`;
  }

  return `${getLocaleMessage(locale.value).Kcal}:\u00A0294.9, ${getLocaleMessage(locale.value).proteins}:\u00A06.6\u00A0${getLocaleMessage(locale.value).gr}., ${getLocaleMessage(locale.value).fats}:\u00A00.9\u00A0${getLocaleMessage(locale.value).gr}., ${getLocaleMessage(locale.value).carbohydrates}:\u00A065\u00A0${getLocaleMessage(locale.value).gr}.`;
})

</script>

<template>
  <v-sheet
    class="w-100"
  >
    <v-sheet
      class="py-4 py-md-10 px-0 d-flex flex-row"
    >
      <v-sheet
        height="220"
        class="px-0 px-sm-4 d-flex align-center flex-column"
      >
        <v-img
          :width="smAndDown ? 180 : 315"
          :height="smAndDown ? 200 : 220"
          :src="product.image_full_url"
          rounded
        />
        <v-card-subtitle
          class="mt-1 text-center d-flex justify-center text-h6 text-black font-weight-bold opacity-100"
        >
          <span class="currency-symbol">{{ product.price }}&nbsp;{{ getCurrencySymbol(menuStore.currentCity) }}
            <span
              style="font-size: 13px"
              class="bold"
            >{{ product.meta_weight }} {{ product.meta_weight_unit === 0 ? $t('gr') + `.` : $t('ml') + `.` }}</span></span>
        </v-card-subtitle>
      </v-sheet>
      <v-sheet>
        <v-sheet
          v-if="product.flags.length > 0"
          class="d-flex flex-row align-center w-100 pa-0 ga-2 mb-1"
        >
          <v-sheet
            v-for="flag in product.flags"
            :key="flag.id"
            class="d-flex align-center justify-start"
            width="25"
            height="20"
          >
            <v-img
              :alt="flag.title"
              width="100%"
              height="auto"
              :src="flag.image_url"
            />
            <v-tooltip
              activator="parent"
              location="top"
            >
              {{ flag.title }}
            </v-tooltip>
          </v-sheet>
        </v-sheet>
        <v-card-title
          class="px-0 no-ellipsis bold text-uppercase"
          :class="mobile ? 'text-h6' : 'text-h5'"
        >
          {{ product.title }}
        </v-card-title>

        <v-card-text class="text-caption custom-line-height pa-0 bold">
          {{ product.description }}
        </v-card-text>

        <v-card-text class="text-caption custom-line-height-2 px-0 pb-3">
          <span
            v-if="product.meta_calories !== 0"
            class="text-caption custom-line-height-2 px-0"
          >{{ $t('Kcal') }}:&nbsp;{{ product.meta_calories }}, </span>
          <span
            v-if="product.meta_proteins !== 0"
            class="text-caption custom-line-height-2 px-0"
          >{{ $t('proteins') }}:&nbsp;{{ product.meta_proteins }}&nbsp;{{ $t('gr') }}., </span>
          <span
            v-if="product.meta_fat !== 0"
            class="text-caption custom-line-height-2 px-0"
          >{{ $t('fats') }}:&nbsp;{{ product.meta_fat }}&nbsp;{{ $t('gr') }}., </span>
          <span
            v-if="product.meta_carbohydrates !== 0"
            class="text-caption custom-line-height-2 px-0"
          >{{ $t('carbohydrates') }}:&nbsp;{{ product.meta_carbohydrates }}&nbsp;{{ $t('gr') }}.</span>
        </v-card-text>

        <v-card-text
          v-if="product.extra_rice"
          class="text-caption custom-line-height-2 px-0 pb-3 pt-0"
        >
          {{ $t('extraRice') }}:<br>
          {{ riceText }}
        </v-card-text>

        <v-sheet
          v-if="
            updatedModifiers.length > 0 &&
              props.product.ingredients.length === 0
          "
          class="d-flex flex-column"
        >
          <v-sheet
            v-for="modifier in updatedModifiers"
            :key="modifier.id"
            class="pb-2"
          >
            <v-select
              v-if="modifier.items"
              v-model="modifier.selected"
              class="font-weight-light text-subtitle-2 pa-0"
              tile
              flat
              hide-details
              :items="modifier.items"
              density="compact"
              :placeholder="modifier.title_form"
              variant="outlined"
              item-value="id"
              item-text="title"
              @update:model-value="
                (selectedId) => updateSelectedModifier(modifier, selectedId)
              "
            >
              <template #selection="{ item }">
                <v-sheet class="pa-0 font-weight-light text-subtitle-2">
                  {{ item.title }}
                </v-sheet>
              </template>
            </v-select>
          </v-sheet>
        </v-sheet>

        <MenuButton
          :add-product="handleAddProductToCart"
          :remove-product="handleRemoveItemFromCart"
          :quantity="cartProduct?.quantity || 0"
          :is-loading="isItemCreation"
        />
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.custom-line-height {
  line-height: 1.5;
}

.custom-line-height-2 {
  line-height: 1.2;
}

.no-ellipsis {
  white-space: normal; /* Разрешить перенос текста на несколько строк */
  text-overflow: unset; /* Убрать многоточие */
  overflow: unset;
  line-height: 1.2;
}
</style>
