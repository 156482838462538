<script setup>
import BaseButton from "@/components/base/BaseButton.vue";
import router from "@/router";
import HeaderContacts from "@/components/Header/HeaderContacts/HeaderContacts.vue";
import HeaderSearch from "@/components/Header/HeaderSearch/HeaderSearch.vue";
import {useAuthStore} from "@/store/AuthStore";
import {useDisplay} from "vuetify";
import {useProfileStore} from "@/store/ProfileStore";
import HeaderHours from "@/components/Header/HeaderHours/HeaderHours.vue";
import HeaderLocalization from "@/components/Header/HeaderLocalization/HeaderLocalization.vue";
import EditProfileDateField from "@/components/Profile/EditProfile/EditProfileDateField.vue";
import {useI18n} from "vue-i18n";

const authStore = useAuthStore();
const profileStore = useProfileStore();
const { mobile, mdAndDown, xs } = useDisplay();
const { getLocaleMessage, locale } = useI18n();

const handleNavigateToCart = () => {
  router.push({
    path: "/cart",
  });
}

const handleLogout = () => {
  router.push({
    path: "/",
  });
  authStore.logout();
}

const openDrawer = () => {
  profileStore.toggleMobileDrawer();
}

</script>

<template>
  <v-sheet
    :class="mobile ? 'pa-2' : 'pa-5'"
    class="border-b bg-white pa-4 container d-flex justify-space-between align-center"
  >
    <v-row
      style="max-width: 1400px"
      class="w-100 header-container pa-0 ma-0 align-center justify-space-between mx-auto ga-2"
      :class="mobile ? 'justify-start' : 'justify-space-between'"
      no-gutters
    >
      <v-col
        cols="auto"
      >
        <HeaderContacts />
      </v-col>

      <v-col
        v-if="!xs"
        cols="3"
        lg="2"
      >
        <header-search />
      </v-col>

      <v-col
        v-if="!mobile"
        cols="auto"
      >
        <header-localization />
      </v-col>

      <v-col
        sm="2"
        cols="3"
        class="d-flex justify-center align-center"
      >
        <v-img
          width="100%"
          height="auto"
          max-height="110"
          src="@/assets/Logo_Tom_Yam.svg"
        />
      </v-col>

      <v-col
        v-if="!mobile"
        cols="2"
      >
        <HeaderHours />
      </v-col>

      <v-col
        v-if="!mdAndDown"
        cols="3"
        class="d-flex align-center justify-end"
      >
        <v-sheet class="d-flex flex-row justify-end ga-2">
          <v-sheet style="max-width: 150px">
            <base-button
              :text="getLocaleMessage(locale).cart"
              @click="handleNavigateToCart"
            />
          </v-sheet>
          <v-sheet
            style="max-width: 150px"
          >
            <base-button
              :text="getLocaleMessage(locale).logout"
              @click="handleLogout"
            />
          </v-sheet>
        </v-sheet>
      </v-col>

      <v-col
        v-if="mdAndDown"
        cols="1"
      >
        <v-app-bar-nav-icon @click="openDrawer" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<style scoped>
.container {
  z-index: 1000;
}
</style>
