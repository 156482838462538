<template>
  <base-layout :is-loading="isLoading">
    <template #header>
      <HeaderComponent />
    </template>
    <MainPage />
  </base-layout>
</template>

<script setup>
import MainPage from "@/components/Home/MainComponent.vue";
import BaseLayout from "@/components/Layouts/BaseLayout.vue";
import HeaderComponent from "@/components/Header/HeaderComponent.vue";
import {computed, onBeforeMount} from "vue";
import {useMenuStore} from "@/store/MenuStore";
import {useAuthStore} from "@/store/AuthStore";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import router from "@/router";
import {storeToRefs} from "pinia";

const menuStore = useMenuStore();
const authStore = useAuthStore();
const isLoading = computed(
    () => menuStore.isLoadingSections || menuStore.isLoadingCities
);

</script>
