<script setup>
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/AuthStore";
import {formatPhoneNumber} from "@/helpers";

const authStore = useAuthStore();
const { user, isAuthenticated } = storeToRefs(authStore);

const handleAuthAction = () => {
  if (isAuthenticated.value) {
    authStore.logout();
  } else {
    authStore.openLoginModal();
  }
};


const handleEditProfile = () => {
  router.push({ path: "/profile/edit" });
};
</script>

<template>
  <div class="auth-container">
    <v-menu>
    <template #activator="{ props }">
        <v-img
            v-bind="props"
            width="25"
            height="25"
            class="rounded-lg cursor-pointer"
            :src="isAuthenticated ? user.avatar_url || require('@/assets/no-avatar.png') : require('@/assets/acc.svg')"
            @click="isAuthenticated ? handleEditProfile() : authStore.openLoginModal()"
            />
    </template>
  </v-menu>
  </div>
</template>
