<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import MenuListNav from "@/components/Header/MenuListNav/MenuListNav.vue";
import HeaderContacts from "@/components/Header/HeaderContacts/HeaderContacts.vue";
import HeaderHours from "@/components/Header/HeaderHours/HeaderHours.vue";
import HeaderSearch from "@/components/Header/HeaderSearch/HeaderSearch.vue";
import HeaderLocalization from "@/components/Header/HeaderLocalization/HeaderLocalization.vue";
import HeaderCart from "@/components/Header/HeaderCart/HeaderCart.vue";
import HeaderProfile from "@/components/Header/HeaderProfile/HeaderProfile.vue";
import HeaderMobileProfile from "@/components/Header/HeaderProfile/HeaderMobileProfile.vue";
import MobileMenuListNav from "@/components/Header/MenuListNav/MobileMenuListNav.vue";
import HeaderMobileCart from "@/components/Header/HeaderCart/HeaderMobileCart.vue";

import { useDisplay } from "vuetify";
import { storeToRefs } from "pinia";
import { useMenuStore } from "@/store/MenuStore";
import router from "@/router";

const isVisible = ref(true);
const { mobile, smAndDown, xs } = useDisplay();
const menuStore = useMenuStore();
const { currentCity } = storeToRefs(menuStore);

const contacts = computed(() => {
  return currentCity.value ? currentCity.value.contacts : [];
});

const yandex = computed(() => {
  return contacts.value.find((contact) => contact.title === 'yandex');
});

const handleScroll = () => {
  const currentScrollPosition = window.scrollY;
  isVisible.value = currentScrollPosition <= 0;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

const handleHome = () => {
  router.push({ path: "/" });
};
</script>

<template>
  <div
    class="w-100"
    :class="mobile ? 'px-3 py-4' : 'py-5 px-4'"
  >
    <!-- Десктопная версия -->
    <template v-if="!mobile">
      <v-sheet
        class="w-100 header-container pa-0 ma-0 align-center mx-auto ga-4 ga-lg-6 d-flex flex-row justify-space-between"
        no-gutters
      >
        <header-localization v-if="isVisible" />
        <HeaderContacts v-if="isVisible" />
        <v-sheet
          class="d-flex flex-row align-center ga-md-5 ga-2"
        >
          <header-search />
          <a
            v-if="yandex"
            :href="yandex.link"
            target="_blank"
            class="cursor-pointer"
          >
            <v-img
              width="30"
              height="30"
              src="@/assets/yandex.png"
            />
          </a>
        </v-sheet>
        <v-sheet
          v-if="isVisible"
          class="d-flex justify-center align-center"
          width="100%"
          :max-width="268"
        >
          <v-img
            width="100%"
            height="auto"
            max-height="86"
            src="@/assets/Logo_Tom_Yam.svg"
            class="cursor-pointer"
            @click="handleHome"
          />
        </v-sheet>
        <MenuListNav v-if="!isVisible" />
        <HeaderHours v-if="isVisible" />
        <HeaderCart />
        <HeaderProfile />
      </v-sheet>
    </template>

    <!-- Мобильная версия -->
    <template v-else>
  <v-sheet
    class="w-100 header-container pa-0 ma-0 align-center mx-auto d-flex flex-column"
  >
    <v-sheet
      class="d-flex justify-space-between align-center"
      style="width: 100%;"
    >
      <v-sheet
        class="d-flex justify-center align-center"
        style="flex: 1;"
      >
        <HeaderContacts />
      </v-sheet>
      <v-sheet
        class="d-flex justify-center align-center"
        style="flex: 1;"
      >
        <v-img
          :style="{ maxWidth: smAndDown ? '200px' : '', height: '32px' }"
          width="100%"
          height="auto"
          max-height="100"
          src="@/assets/Logo_Tom_Yam.svg"
          class="cursor-pointer"
          @click="handleHome"
        />
      </v-sheet>
      <v-sheet
        class="d-flex justify-space-around align-center"
        style="flex: 1;"
      >
        <header-localization />
        <HeaderMobileProfile />
      </v-sheet>

    </v-sheet>
    <header-mobile-cart />
  </v-sheet>
</template>

  </div>
</template>

<style scoped>
.container {
  z-index: 1000;
}
.header-container {
  max-width: 1400px;
}
</style>
