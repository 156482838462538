<script setup>
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import {useAuthStore} from "@/store/AuthStore";
import {useDisplay} from "vuetify";
import router from "@/router";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import {useCartStore} from "@/store/CartStore";
import {useI18n} from "vue-i18n";

const menuStore = useMenuStore();
const authStore = useAuthStore();
const cartStore = useCartStore();
const {cities, currentCity, isLoading} = storeToRefs(menuStore);
const { locale } = useI18n();

const handleSelectCity = async (newVal) => {
  await menuStore.setCurrentCity(newVal)
  const route = CyrillicToTranslit().transform(newVal.title).toLowerCase();

  if (authStore.isAuthenticated) {
    await authStore.editProfile({city_id: newVal.id})
    await cartStore.getCart(locale.value);
  }

  await router.push({
    path: `/${route}`,
  });
}
const { mobile } = useDisplay();

const handleNavigateToFooter = () => {
  router.push({
    hash: `#footer`,
  });
}
</script>

<template>
  <div class="d-flex flex-column header-contacts-container">
    <v-sheet
      class="semibold ma-0 pa-0 d-none d-md-block cursor-pointer text-uppercase"
      style="line-height: 1"
      @click="handleNavigateToFooter"
    >
      {{ $t('contacts') }}
    </v-sheet>
    <div class="d-flex flex-row align-items-center justify-center">
      <v-select
        v-model="currentCity"
        hide-details
        :flat="true"
        class="custom-select pa-0"
        :items="cities"
        bg-color="white"
        tile
        return-object
        :style="{fontSize: mobile ? '14px' : '16px', padding: 0} "
        density="compact"
        placeholder="Город"
        :loading="isLoading"
        menu-icon=""
        variant="plain"
        @update:model-value="handleSelectCity"
      >
        <template #prepend-inner>
          <v-icon
            class="custom-icon mr-1"
            size="x-small"
            color="black"
            icon="mdi-map-marker"
          />
        </template>

        <div
          v-for="item in cities"
          :key="item"
          :style="{fontSize: mobile ? '14px' : '16px'} "
        >
          {{ item.title }}
        </div>
      </v-select>
    </div>
  </div>
</template>

<style>
.header-contacts-container {
  min-width: 120px;
  .v-input__control {
    max-height: 32px;
  }
  .v-field {
    padding: 0;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
  .v-field__input {
    padding: 0;
  }

  i {
    opacity: 1!important;
  }
}

.title {
  font-size: 16px;
  line-height: 16px;
}
</style>
