<script setup>
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/AuthStore";
import {formatPhoneNumber} from "@/helpers";

const authStore = useAuthStore();
const { user, isAuthenticated } = storeToRefs(authStore);

const handleAuthAction = () => {
  if (isAuthenticated.value) {
    authStore.logout();
  } else {
    authStore.openLoginModal();
  }
};

const handleOrderHistory = () => {
  router.push({ path: "/profile/history" });
};

const handleEditProfile = () => {
  router.push({ path: "/profile/edit" });
};
</script>

<template>
  <div class="auth-container">
    <v-menu>
    <template #activator="{ props }">
      <v-img
        v-bind="props"
        width="40"
        height="40"
        class="rounded-lg cursor-pointer"
        :src="isAuthenticated ? user.avatar_url || require('@/assets/no-avatar.png') : require('@/assets/acc.svg')"
      />
    </template>

    <v-list>
      <v-list-item v-if="isAuthenticated">
        <v-list-item-content>
          <v-list-item-title class="user-name">{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle class="user-phone">{{ formatPhoneNumber(user.phone_number) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="handleOrderHistory">
        <v-list-item-title class="menu-item">{{ $t('orderHistory') }}</v-list-item-title>
      </v-list-item>

      <v-list-item @click="handleEditProfile">
        <v-list-item-title class="menu-item">Редактирование профиля</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
    <span class="auth-action" @click="handleAuthAction">
      {{ isAuthenticated ? $t('logout') : "Войти" }}
    </span>
  </div>
</template>

<style scoped>
.auth-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.auth-action {
  color: crimson;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.user-name {
  font-weight: bold;
  color: black;
  font-size: 18px;
}

.user-phone {
  font-size: 0.9rem;
  color: grey;
  font-size: 12px;
}

.menu-item {
  font-weight: bold;
  color: black;
  font-size: 14px;
}

</style>