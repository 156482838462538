<script setup>
import { useAlertStore } from '@/store/AlertStore';
import { storeToRefs } from 'pinia';
import BaseButton from "@/components/base/BaseButton.vue";

const alertStore = useAlertStore();
const { isOpen, type, message } = storeToRefs(alertStore);
const { closeAlert } = alertStore;
</script>

<template>
  <v-dialog
    v-model="isOpen"
    width="100%"
    max-width="500"
  >
    <v-sheet
      rounded="xl"
      class="pt-6 pb-12 px-8 d-flex justify-center align-center flex-column"
    >
      <v-sheet
        width="100%"
        max-width="260"
      >
        <v-img src="@/assets/Logo_Tom_Yam.svg" />
      </v-sheet>
      <v-card-text
      class="text-center text-customRed text-h4 font-weight-bold"
    >
      {{ type === 'error' ? 'Ошибка!' : type === 'warning' ? 'Внимание!' : 'Получилось!' }}
    </v-card-text>


      <v-sheet
        class="d-flex justify-center align-center flex-column ga-4 px-4"
      >
        <v-sheet class="semibold text-center">
          {{ message }}
        </v-sheet>
        <v-sheet
          max-width="220"
          class="pa-0 ma-0"
        >
          <base-button
            text="ОК"
            @click="closeAlert"
          />
        </v-sheet>
      </v-sheet>
    </v-sheet>
  </v-dialog>
</template>

<style scoped>
/* Add any custom styles if needed */
</style>
