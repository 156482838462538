<template>
  <base-sheet>
    <base-title class="mb-4 semibold">
      {{ $t('total') }}
    </base-title>
    <v-container
      v-for="(item, index) in dataEntries"
      :key="index"
      class="pa-0 d-flex justify-space-between align-center"
    >
      <check-item :item="item" />
    </v-container>
  </base-sheet>
</template>

<script setup>
import BaseSheet from "@/components/base/BaseSheet/BaseSheet.vue";
import BaseTitle from "@/components/base/BaseTitle/BaseTitle.vue";
import { computed } from "vue";
import CheckItem from "@/components/Cart/CartCheck/CheckItem/CheckItem.vue";
import {useCartStore} from "@/store/CartStore";
import {storeToRefs} from "pinia";
import {formatPrice, getCurrencySymbol} from "@/helpers";
import {useMenuStore} from "@/store/MenuStore";
import {useI18n} from "vue-i18n";

const cartStore = useCartStore();
const { total_price, delivery_price, bagsInfo, orderProcessingInfo, fullCartPrice, isShowDeliveryPrice } = storeToRefs(cartStore)
const { currentCity } = storeToRefs(useMenuStore());
const { locale, getLocaleMessage } = useI18n();

const data = computed(() => {
  return {
    itemsPrice: {
      name: getLocaleMessage(locale.value).inTotal,
      value: `${formatPrice(fullCartPrice.value)} ${getCurrencySymbol(currentCity.value)}`,
    },
    bags: {
      name: `${getLocaleMessage(locale.value).bag} x ${bagsInfo.value.quantity}`,
      value: `${formatPrice(bagsInfo.value.price)} ${getCurrencySymbol(currentCity.value)}`,
    },
    makingOrder: {
      name: getLocaleMessage(locale.value).orderProcessing,
      value: `${formatPrice(orderProcessingInfo.value.price)} ${getCurrencySymbol(currentCity.value)}`,
    },
    deliveryPrice: {
      name: getLocaleMessage(locale.value).delivery,
      value: `${isShowDeliveryPrice.value ? formatPrice(delivery_price.value) + ' ' + getCurrencySymbol(currentCity.value) : getLocaleMessage(locale.value).enterAddress}`,
    },
    totalPrice: {
      // name: "Итого",
      name: getLocaleMessage(locale.value).total,
      value: `${formatPrice(total_price.value)} ${getCurrencySymbol(currentCity.value)}`,
    },
  }
})

const dataEntries = computed(() =>
  Object.entries(data.value).map(([key, value]) => ({ key, ...value }))
);
</script>
