import { cityMetrics } from '@/constants/metrika';

let YM_ID = null; // Переменная для хранения текущей метрики

/**
 * Устанавливает текущую метрику по ID города
 * @param {string} cityId - ID города
 * @throws {Error} - Если метрика для города не найдена
 */
export function setCityMetrika(cityId) {
  if (cityMetrics[cityId]) {
    YM_ID = cityMetrics[cityId];
    console.log(`Текущая метрика установлена: ${YM_ID}`);
  } else {
    console.log(`Метрика для города с ID ${cityId} не найдена.`);
  }
}

/**
 * Возвращает текущую метрику
 * @returns {string} - Текущий YM_ID
 * @throws {Error} - Если метрика не установлена
 */
export function getCurrentMetrika() {
  if (YM_ID) {
    return YM_ID;
  } else {
    console.log('Текущая метрика не установлена. Используйте setCityMetrika.');
  }
}
