import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import {CATEGORIES, CITIES, WORK_SCHEDULE_HOURS} from '@/api/queries';
import apolloClient from '../../apolloClient';
import { useCartStore } from '@/store/CartStore';
import Cookies from "js-cookie";
import {COOKIE_LANGUAGE_NAME} from "@/constants/cookies";
import { setCityMetrika } from "@/utils/metrika";

export const useMenuStore = defineStore('MenuStore', () => {
  // State
  const sections = ref([]);
  const activeSection = ref(0);
  const cities = ref([]);
  const currentCity = ref(null);
  const isLoadingSections = ref(true);
  const isLoadingCities = ref(true);
  const ingredientsModalState = ref({
    isOpen: false,
    product: null,
  });
  const currentCityWorkHours = ref(null);

  const activeSectionId = computed(() => activeSection.value);

  const totalCartPrice = computed(() => {
    return sections.value.reduce((total, section) => {
      const sectionTotal = section.products.reduce((sectionSum, product) => {
        return sectionSum + product.price * product.quantity;
      }, 0);
      return total + sectionTotal;
    }, 0);
  });

  const sectionsToShow = computed(() => {
    return sections.value
        .filter((section) => section.active)
        .filter((section) => section.title !== 'Прочее')
        .filter((section) => section.products.length !== 0)
        .filter((section) => !section.products.every((item) => item.price === 0))
        .sort((a, b) => a.priority - b.priority);
  });

  const allProducts = computed(() => {
    return sectionsToShow.value.reduce((allProducts, section) => {
      return allProducts.concat(section.products);
    }, []);
  })

  const getMenuSections = async (cityId = 1, locale = 'ru') => {
    isLoadingSections.value = true;
    try {
      const { items } = storeToRefs(useCartStore());
      const response = await apolloClient.query({
        query: CATEGORIES,
        variables: {
          city_id: cityId,
          locale
        },
      });
      sections.value = response.data.categories.map((section) => {
        return {
          ...section,
          products: section.products.map((product) => {
            const existingProduct = items.value.find(
                (item) => item.product.id === product.id
            );
            return existingProduct
                ? {
                  ...product,
                  quantity: existingProduct.quantity,
                }
                : {
                  ...product,
                  quantity: 0,
                };
          }),
        };
      });
      activeSection.value = response.data.categories[0];
    } catch (err) {
      console.error('Failed to fetch products:', err);
    } finally {
      isLoadingSections.value = false;
    }
  };

  const getCities = async () => {
    isLoadingCities.value = true;
    try {
      const response = await apolloClient.query({
        query: CITIES,
      });
      cities.value = response.data.cities;
    } catch (err) {
      console.error('Failed to fetch cities:', err);
    } finally {
      isLoadingCities.value = false;
    }
  };

  const getWorkScheduleHours = async (cityId) => {
    isLoadingSections.value = true;
    try {
      const response = await apolloClient.query({
        query: WORK_SCHEDULE_HOURS,
        variables: {
          city_id: cityId,
        },
      });

      currentCityWorkHours.value = response.data.workScheduleHours;

    } catch (err) {
      console.error('Failed to fetch work hours:', err);
    } finally {
      isLoadingSections.value = false;
    }
  };


  const setCurrentCity = async (city) => {
    const lang = Cookies.get(COOKIE_LANGUAGE_NAME);
    await getMenuSections(city.id, lang);
    await getWorkScheduleHours(city.id);
    await setCityMetrika(city.id);
    currentCity.value = { ...city };
  };

  const changeActiveSection = (section) => {
    if (section) {
      activeSection.value = section;
    }
  };

  const addProductToCart = async (newProduct, modifiers = [], ingredients = []) => {
    try {
      const cartStore = useCartStore();
      await cartStore.addToCart(newProduct, modifiers, ingredients);
    } catch (e) {
      console.error(e);
    }
  };

  const removeProductFromCart = async (newProduct) => {
    const cartStore = useCartStore();
    if (newProduct.quantity === 1) {
      await cartStore.removeFromCart(newProduct.id);
    } else {
      await cartStore.cartItemUpdate(newProduct.id, newProduct.quantity - 1);
    }
  };

  const openIngredientsModal = (product) => {
    ingredientsModalState.value = {
      isOpen: true,
      product,
    };
  };

  const closeIngredientsModal = () => {
    ingredientsModalState.value.isOpen = false;
  };

  return {
    sections,
    activeSection,
    cities,
    currentCity,
    isLoadingSections,
    isLoadingCities,
    ingredientsModalState,
    activeSectionId,
    totalCartPrice,
    sectionsToShow,
    allProducts,
    currentCityWorkHours,
    getMenuSections,
    getCities,
    setCurrentCity,
    changeActiveSection,
    addProductToCart,
    removeProductFromCart,
    openIngredientsModal,
    closeIngredientsModal,
  };
});
