<script setup>
import router from "@/router";
import {useCartStore} from "@/store/CartStore";
import {storeToRefs} from "pinia";
import {useMenuStore} from "@/store/MenuStore";
import {formatPrice, getCurrencySymbol} from "@/helpers";
import {computed} from 'vue';

const { total_price } = storeToRefs(useCartStore());
const { currentCity } = storeToRefs(useMenuStore())

const handleNavigateToCart = () => {
  router.push({
    path: "/cart",
  });
};

const isCartEmpty = computed(() => total_price.value === 0);

</script>

<template>
  <v-sheet
    class="cursor-pointer d-flex align-items-center justify-content-between white-bg shadow-card"
    style="font-size: 16px; font-weight: 600; padding: 10px; border-radius: 8px;"
    @click="handleNavigateToCart"
  >
    <div style="width: 45px; display: flex; justify-content: center; align-items: center;">
      <v-img
        src="@/assets/btn_shop.svg"
        width="25px"
        height="25px"
        :class="{'red-icon': !isCartEmpty}"
      />
    </div>

    <div style="width: 100px; display: flex; justify-content: center; align-items: center;" class="semibold currency-symbol">
      <v-sheet
        :class="{'red-text': !isCartEmpty}"
      >
        {{ formatPrice(total_price) }}&nbsp;{{ getCurrencySymbol(currentCity) }}
      </v-sheet>
    </div>
  </v-sheet>
</template>



<style scoped>
.white-bg {
  background-color: white;
  width: 145px;
  height: 40px;
}

.shadow-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.red-icon {
  filter: invert(11%) sepia(94%) saturate(6831%) hue-rotate(360deg) brightness(99%) contrast(108%);
}

.red-text {
  color: #ff0000;
}
</style>

