<script setup>

import FooterBlock from "@/components/Footer/FooterBlock.vue";
import {computed} from "vue";
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import {formatPhoneNumber, getAddressWorkingHours} from "@/helpers";

const menuStore = useMenuStore();
const { currentCity, currentCityWorkHours } = storeToRefs(menuStore)

const addresses = computed(() => {
  return currentCity.value
    ? currentCity.value.addresses.filter(address => address.delivery_possible)
    : [];
});

const contacts = computed(() => {
  return currentCity.value ? currentCity.value.contacts : [];
})

const yandex = computed(() => {
  return contacts.value.find((contact) => contact.title === 'yandex');
})

const entities = computed(() => {
  return currentCity.value ? currentCity.value.entities : [];
})

const deliveryClub = computed(() => {
  return contacts.value.find((contact) => contact.title === 'DeliveryClub');
})

const telegram = computed(() => contacts.value.find((contact) => contact.title === 'telegram_group'))

const facebook = computed(() => contacts.value.find((contact) => contact.title === 'facebook'))

const instagram = computed(() => contacts.value.find((contact) => contact.title === 'instagram'))

const vk = computed(() => contacts.value.find((contact) => contact.title === 'vk'))

const getAddressContact = (address, name) => address.contacts.find((contact) => contact.title === name)

</script>

<template>
  <v-container
    id="footer"
    fluid
    class="footer ma-0 text-white py-12"
  >
    <v-sheet
      max-width="1432"
      class="mx-auto bg-transparent"
    >
      <v-sheet class="d-flex flex-column flex-md-row align-center align-md-start justify-space-between bg-transparent text-center text-md-left  ga-10 ga-md-4">
        <v-sheet
          class="d-flex flex-column bg-transparent flex-1-0-0"
          max-width="45%"
        >
          <v-sheet
            class="d-flex flex-lg-row flex-column justify-space-between ga-6 bg-transparent"
          >
            <footer-block
              :title="$t('deliveryMode')"
            >
              <v-sheet class="ma-0 pa-0 d-flex flex-column bg-transparent text-subtitle-2 text-white">
                <v-sheet
                  v-for="address in addresses"
                  :key="address.id"
                  class="bg-transparent d-flex flex-column mb-4"
                >
                  <div>
                    {{ currentCity.type }} {{ currentCity.title }}, {{ address.title }}
                  </div>

                  <v-sheet
                    v-if="getAddressWorkingHours(address.id, currentCityWorkHours) !== undefined"
                    class="bg-transparent"
                  >
                    <v-sheet
                      v-for="hour in getAddressWorkingHours(address.id, currentCityWorkHours)"
                      :key="hour"
                      class="bg-transparent"
                    >
                      {{ hour }}
                    </v-sheet>
                  </v-sheet>
                </v-sheet>
              </v-sheet>
            </footer-block>
            <v-sheet class="bg-transparent d-flex flex-row align-start justify-center justify-md-start justify-lg-center ga-2">
              <a
                v-if="yandex && yandex.link"
                :href="yandex.link"
                target="_blank"
                class="cursor-pointer d-flex justify-center align-center"
              >
                <v-img
                  width="32"
                  height="32"
                  src="@/assets/yandex.png"
                />
              </a>

              <a
                v-if="deliveryClub && deliveryClub.link"
                :href="deliveryClub.link"
                target="_blank"
                class="cursor-pointer"
              >
                <v-img
                  width="30"
                  height="30"
                  src="@/assets/delivery_club.svg"
                />
              </a>
            </v-sheet>
            <footer-block
              :title="$t('forPartners')"
            >
              <v-sheet class="ma-0 pa-0 d-flex flex-column bg-transparent text-decoration-underline text-subtitle-2 text-white ">
                <a
                  href="https://tomyumbar.com/franchise"
                  target="_blank"
                  class="text-white"
                >
                  {{ $t('franchise') }}
                </a>
                <a
                  href="https://tomyumbar.com/application"
                  target="_blank"
                  class="text-white"
                >
                  {{ $t('franchiseApplication') }}
                </a>
              </v-sheet>
            </footer-block>
          </v-sheet>
          <v-sheet class="bg-transparent pt-10 d-none flex-column ga-2 d-md-flex">
            <v-sheet
              v-for="entity in entities"
              :key="entity"
              class="bg-transparent text-center text-no-wrap text-subtitle-1 regular d-flex flex-row align-center justify-center"
              style="line-height: 1.4"
            >
              <v-sheet class="bg-transparent d-inline">
                {{ entity.title }}
              </v-sheet>
              <v-sheet
                v-if="entity.inn"
                class="bg-transparent d-inline"
              >
                , ИНН: {{ entity.inn }}
              </v-sheet>
            </v-sheet>
          </v-sheet>
        </v-sheet>
        <v-sheet class="bg-transparent">
          <footer-block
            :title="$t('ourContacts')"
          >
            <v-sheet class="ma-0 pa-0 d-flex flex-column bg-transparent text-subtitle-2 text-white">
              <v-sheet
                v-for="address in addresses"
                :key="address.id"
                class="bg-transparent d-flex flex-column mb-4"
              >
                <a
                  v-if="getAddressContact(address, 'Phone')"
                  class="text-white text-decoration-underline"
                  :href="`tel:${getAddressContact(address, 'Phone').link}`"
                >
                  {{ formatPhoneNumber(getAddressContact(address, 'Phone').link) }}
                </a>
                <div>
                  {{ currentCity.type }} {{ currentCity.title }}, {{ address.title }}
                </div>

                <a
                  v-if="getAddressContact(address, 'Email')"
                  :href="`mailto:${getAddressContact(address, 'Email').link}`"
                  class="text-white text-decoration-underline"
                >
                  {{ getAddressContact(address, 'Email').link }}
                </a>
              </v-sheet>
            </v-sheet>
          </footer-block>
        </v-sheet>
        <v-sheet class="bg-transparent">
          <footer-block
            :title="$t('socials')"
          >
            <v-sheet class="ma-0 pa-0 d-flex flex-row justify-center ga-3 mt-3 bg-transparent">
              <a
                v-if="telegram"
                :href="telegram.link"
                target="_blank"
              >
                <v-img
                  width="24px"
                  height="24px"
                  src="@/assets/telegram_footer.svg"
                />
              </a>
              <a
                v-if="vk"
                href="https://vk.com/tomyumbar"
                target="_blank"
              >
                <v-img
                  width="24px"
                  height="24px"
                  src="@/assets/vk_footer.svg"
                />
              </a>
              <a
                v-if="facebook"
                :href="facebook.link"
                target="_blank"
              >
                <v-img
                  width="24px"
                  height="24px"
                  src="@/assets/facebook_footer.svg"
                />
              </a>
              <a
                v-if="instagram"
                :href="instagram.link"
                target="_blank"
              >
                <v-img
                  width="24px"
                  height="24px"
                  src="@/assets/instagram_footer.svg"
                />
              </a>
            </v-sheet>
          </footer-block>
        </v-sheet>
      </v-sheet>
      <v-sheet class="bg-transparent d-flex align-center justify-center flex-column flex-md-row mt-5 ga-4">
        <a
          v-if="entities[0] && entities[0].delivery"
          class="text-white text-decoration-underline"
          :href="entities[0].delivery || ''"
          target="_blank"
        > {{ $t('deliveryAndReturnConditions') }}</a>
        <a
          v-if="entities[0] && entities[0].payment"
          class="text-white text-decoration-underline"
          :href="entities[0].payment || ''"
          target="_blank"
        >{{ $t('paymentProcess') }}</a>
        <a
          v-if="entities[0] && entities[0].privacy"
          class="text-white text-decoration-underline"
          :href="entities[0].privacy || ''"
          target="_blank"
        >{{ $t('privacyPolicy') }}</a>
      </v-sheet>
      <v-sheet class="bg-transparent pt-10 pl-4 d-flex flex-column ga-2 d-md-none">
        <v-sheet
          v-for="entity in entities"
          :key="entity"
          class="bg-transparent text-center text-no-wrap text-subtitle-1 regular d-flex flex-column flex-sm-row align-center justify-center ga-1 ga-sm-2"
          style="line-height: 1.4"
        >
          <v-sheet class="bg-transparent">
            {{ entity.title }}
          </v-sheet>

          <v-sheet
            v-if="entity.inn"
            class="bg-transparent "
          >
            ИНН: {{ entity.inn }}
          </v-sheet>
        </v-sheet>
      </v-sheet>
      <v-sheet class="bg-transparent d-flex align-center justify-center mt-5">
        TomYumBar © {{ new Date().getFullYear() }}
      </v-sheet>
    </v-sheet>
  </v-container>
</template>

<style scoped>
.footer {
  background-color: #282828;
}
</style>
