<script setup>
import {computed, ref, watch} from 'vue';
import { debounce } from 'lodash';
import { appConfig } from "../../../config";
import axios from "axios";
import {VLabel} from "vuetify/components";
import {storeToRefs} from "pinia";
import {useMenuStore} from "@/store/MenuStore";
import {string} from "yup";
import {
  YandexMap, YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapListener,
  YandexMapMarker,
} from "vue-yandex-maps";
import BaseButton from "@/components/base/BaseButton.vue";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  errorMessages: {
    type: String,
    default: "",
    required: false
  },
  onBlur: {
    type: Function,
    default: () => {},
    required: false,
  },
  onSelect: {
    type: Function,
    default: () => {},
    required: false,
  },
  preparedCity: {
    type: string,
    default: "",
    required: false,
  }
});
const emit = defineEmits(['update:modelValue']);
const isLoading = ref(false);
const suggestions = ref([]);
const localValue = ref(''); // Для строки, введенной пользователем
const selectedSuggestion = ref(null); // Для выбранной подсказки
const {currentCity} = storeToRefs(useMenuStore())

const fetchSuggestions = debounce(async (term) => {
  if (!term) {
    suggestions.value = [];
    return;
  }
  isLoading.value = true;
  try {
    const { data } = await axios.post(`${appConfig.API_URL}/address`, {
      term: props.preparedCity ? props.preparedCity + ' ' + term : currentCity?.value.title + ' ' + term,
    });
    suggestions.value = Object.values(data);
  } catch (error) {
    console.error(error);
  }
  isLoading.value = false;
}, 600);

watch(localValue, (newValue) => {
  console.log('new', newValue)
  // Отправляем запрос на получение подсказок только для текста, введенного пользователем
  if (typeof newValue === 'string') {
    fetchSuggestions(newValue);
  } else {
    emit('update:modelValue', localValue.value);
    if (props.onSelect) {
      props.onSelect(localValue.value); // Вызываем переданный родительский обработчик
    }
  }
});

watch(() => props.modelValue, (newVal) => {
  if (selectedSuggestion.value !== newVal) {
    selectedSuggestion.value = newVal;
  }
});

const mapCenter = computed(() => {
  const validAddress = currentCity.value.addresses.find(
      (address) => address.geo_lat && address.geo_lon
  );
  return validAddress
      ? [validAddress.geo_lon, validAddress.geo_lat]
      : [82.8964, 54.9833];
});

const markerCoords = ref([82.8964, 54.9833]);
const selectedAddress = ref(''); // Адрес, полученный по клику

const checkGeoAddress = async (lat, lon) => {
  try {
    const { data } = await axios.get(`${appConfig.API_URL}/address/geolocate?lat=${lat}&lon=${lon}`);
    return data;
  } catch (e) {
    console.error(e);
  }
}

// Обработка клика на карте
const handleMapClick = async (data) => {
  if (!data) return;
  const lat = data.entity.geometry.coordinates[1];
  const lon = data.entity.geometry.coordinates[0];
  markerCoords.value = data.entity.geometry.coordinates;
  const addresses = await checkGeoAddress(lat, lon);
  if (addresses && addresses.length > 0) {
    selectedAddress.value = addresses[0];
    localValue.value = addresses[0].value;
    if (props.onSelect) {
      props.onSelect(addresses[0]); // Вызываем переданный родительский обработчик
    }
  }
};

const handleClickSaveMapAddress = () => {
  emit('update:modelValue', selectedAddress.value);
}

</script>

<template>
  <v-sheet class="w-100 d-flex flex-row align-center">
    <v-sheet class="w-100 flex-1-0-0">
      <v-label
        class="text-black opacity-100 w-100 semibold mb-0 text-start"
      >
        {{
          label
        }}
      </v-label>
      <v-combobox
        v-model="localValue"
        class="w-100"
        :items="suggestions"
        variant="underlined"
        :loading="isLoading"
        item-title="value"
        :hide-no-data="true"
        :clear-on-select="false"
        :return-object="true"
        no-filter
        :placeholder="placeholder"
        @select="selectedSuggestion = $event"
        @blur="onBlur"
      />
      <v-card-subtitle
        v-if="errorMessages"
        class="text-customRed"
      >
        {{
          errorMessages
        }}
      </v-card-subtitle>
    </v-sheet>
    <v-dialog height="800px">
      <template #activator="{ props: activatorProps }">
        <v-btn
          v-bind="activatorProps"
          color="customRed"
          variant="flat"
          size="small"
          icon="mdi-map-search-outline"
        />
      </template>

      <template #default="{ isActive }">
        <v-card
          tile
          title="Выберите адрес"
        >
          <template #append>
            <v-btn
              icon="mdi-close"
              @click="isActive.value = false"
            />
          </template>
          <yandex-map
            :settings="{
              location: {
                center: mapCenter,
                zoom: 9,
              },
            }"
            width="100%"
            height="800px"
          >
            <yandex-map-default-scheme-layer />
            <yandex-map-default-features-layer />

            <yandex-map-marker
              :settings="{
                coordinates: markerCoords,
              }"
              position="center"
            >
              <v-icon
                size="x-large"
                icon="mdi-map-marker"
                color="#e41d30"
              />
            </yandex-map-marker>

            <yandex-map-listener
              :settings="{
                onClick: handleMapClick,
              }"
            />
          </yandex-map>
          <v-sheet
            class="position-absolute bottom-0 left-0 w-100 pa-4 text-h5 d-flex flex-row align-center justify-space-between"
          >
            <v-sheet>
              Адрес: <span class="text-subtitle-1">{{ selectedAddress ? selectedAddress.value : '-' }}</span>
            </v-sheet>

            <base-button
              text="Подтвердить"
              :disabled="selectedAddress.length === 0"
              max-width="120px"
              :on-click="() => {
                isActive.value = false;
                handleClickSaveMapAddress();
                onBlur && onBlur();
              }"
            />
          </v-sheet>
        </v-card>
      </template>
    </v-dialog>
  </v-sheet>
</template>

<style scoped>
</style>
