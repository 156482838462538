

<script setup>
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";

const {currentCityWorkHours} = storeToRefs(useMenuStore());

</script>


<template>
  <v-sheet class="d-flex flex-column align-items-start px-2">
    <v-sheet class="d-flex align-items-center" style="margin-bottom: 8px;">
      <v-img src="@/assets/clock_icon.svg" style="width: 16px; height: 16px; margin-right: 8px;" />
      <div style="font-size: 16px; font-weight: 600; line-height: 18px;">
        {{ $t('workingHours') }}
      </div>
    </v-sheet>

    <v-sheet
      v-if="currentCityWorkHours !== null && currentCityWorkHours[0] !== undefined"
      class="bg-transparent pt-2"
    >
      <v-sheet
        v-for="hour in currentCityWorkHours[0].hours"
        :key="hour"
        class="bg-transparent"
      >
        {{ hour }}
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>




<!-- <div class="text-subtitle-1 text-uppercase">
      {{ $t('workingHours') }}
    </div>
    <v-sheet
      v-if="currentCityWorkHours !== null && currentCityWorkHours[0] !== undefined"
      class="bg-transparent pt-2"
    >
      <v-sheet
        v-for="hour in currentCityWorkHours[0].hours"
        :key="hour"
        class="bg-transparent"
      >
        {{ hour }}
      </v-sheet>
    </v-sheet> -->
