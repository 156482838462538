<script setup>
import {useAlertStore} from "@/store/AlertStore";
import {storeToRefs} from "pinia";

const alertStore = useAlertStore();
const { isOpenToast, type, message, toastTitle } = storeToRefs(alertStore);

</script>

<template>
  <v-alert
    v-model="isOpenToast"
    icon="mdi-close"
    :text="message"
    :title="toastTitle"
    :type="type"
    position="fixed"
    border="start"
    close-label="Close Alert"
    variant="elevated"
    closable
    style="z-index: 1000; bottom: 20px; right: 20px"
  >
    <template #prepend />
  </v-alert>
</template>

<style scoped>

</style>
