import {ApolloClient, InMemoryCache, createHttpLink, from} from '@apollo/client/core';
import {appConfig} from "./config";
import {setContext} from "@apollo/client/link/context";
import {useAuthStore} from "@/store/AuthStore";
import {onError} from "@apollo/client/link/error";
import {useAlertStore} from "@/store/AlertStore";
import {getActivePinia} from "pinia";
import {useMenuStore} from "@/store/MenuStore";
import {formatPhoneNumber} from "@/helpers";
import Cookies from "js-cookie";
import {COOKIE_LANGUAGE_NAME} from "@/constants/cookies";

const httpLink = createHttpLink({
    uri: `${appConfig.API_URL}/graphql`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    const alertStore = getActivePinia() ? useAlertStore() : null;
    const menuStore = getActivePinia() ? useMenuStore() : null;
    const authStore = getActivePinia() ? useAuthStore() : null;

    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            if (message === 'Unauthenticated.') {
                authStore.openLoginModal();
                return;
            }
            if (alertStore) {
                alertStore.openAlert('error', message);
            }
        });
    }

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
        if (alertStore) {
            if (networkError.message === 'NetworkError when attempting to fetch resource.') {
                // Общая ошибка сети
                alertStore.openAlert('error', 'Потеряно соединение с интернетом.');
                // @ts-ignore
            } else if (networkError.message === 'Failed to fetch') {
                // Конкретная ошибка `ERR_INTERNET_DISCONNECTED`
                const phone = menuStore.currentCity.contacts.find((contact) => contact.title === 'phone');
                alertStore.openAlert('error', `Интернет временно недоступен. Для заказа любимых блюд позвоните по телефону ${formatPhoneNumber(phone.link)}`);
            }  else {
                // Обработка других сетевых ошибок
                alertStore.openAlert('error', networkError.message);
            }
        }
    }
});

const combinedHeaders = setContext((_, { headers }) => {
    const authStore = useAuthStore();
    const lang = Cookies.get(COOKIE_LANGUAGE_NAME)
    const token = authStore.token;
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            locale: lang,
        },
    };
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    link: from([combinedHeaders, errorLink, httpLink]),
    cache,
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            notifyOnNetworkStatusChange: true,
        },
    },

});

export default apolloClient;
